@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-color: #1113a2;
  --title-color: rgba(0, 0, 0, 0.72);
  --gray-color: #353634;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', serif;
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 0 !important;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}
/* .row > * {
  width: 100%;
  max-width: 100%;
  padding-right: 0 !important;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
} */

.pointer {
  cursor: pointer;
}
